define('slider', ['jquery'], function ($) {

	var slider = {
		startSlider: function() {
	    if ($('.slider').children().length > 1) {
	      $('.slider').slick({
	        dots: true,
	        infinite: true,
	        speed: 500,
	        fade: true,
	        cssEase: 'linear'
	      });
	    }
		},
		init: function() {
			this.startSlider();
		}
	};

	return slider;
});

