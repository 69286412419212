define('share', ['jquery'], function ($) {

	var share = {
		shareButton: $('#js_productShareBtn'),
		nativeShare: function() {
			if(!!navigator.share) {
				this.shareButton.show().on('click', function(e) {
					e.preventDefault();
					navigator.share({
						title: document.title,
						text: "Ein buntes Zirkusprojekt für Kinder.",
						url: $('[rel="canonical"]').attr('href'),
					});
				});
			}
		},
		init: function() {
			this.nativeShare();
		}
	};

	return share;
});

