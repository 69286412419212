require([
  'jquery',
  'slick',
  'navigation',
  'slider',
  'share'
], function (
  $,
  slick,
  navigation,
  slider,
  share
) {
  // init serviceWorker
  var swActive = true;
  if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/serviceWorker.js').then(function(registration) {
      if (!swActive) {
        registration.unregister();
      }
    });
  }

  // init ga
  (function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function() {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

  ga('create', 'UA-31204128-1', 'auto');
  ga('send', 'pageview');

  $(document).ready(function() {
    navigation.init();
    slider.init();
    share.init();
  });
});

define("app", function(){});

