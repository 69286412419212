define('navigation', ['jquery'], function ($) {

	var navigation = {
		startNavigation: function() {

	    $('.fa-bars').on('click', function() {
	      if (!$('.navigation__main').is(':visible')) {
	        $('.navigation__main').slideDown();
	      } else {
	        $('.navigation__main').slideUp();
	      }
	    });
		},
		init: function() {
			this.startNavigation();
		}
	};

	return navigation;
});

